<template>
  <course-session-registration-wizard-step
    icon="ph:bank"
    title="Customer"
    @submit="onSubmit"
  >
    <customer-upsert-widget
      v-model:customer="existing"
      v-model:customer-creation-request="request"
      :disabled="!!properties.context.user.existing?.customer"
    />

    <hubspot-deal-line-item-widget
      v-model:deal-id="hubspot.deal_id"
      v-model:line-item-ids="hubspot.line_item_ids"
      :max-line-items="1"
      required-deal-id
      required-line-item-ids
    />
  </course-session-registration-wizard-step>
</template>

<script lang="ts" setup>
import type {
  CourseSessionRegistrationWizardContext,
  CourseSessionRegistrationWizardEvent,
} from "~/business-areas/course-session-registration/course-session-registration.model";

const properties = defineProps<{
  context: CourseSessionRegistrationWizardContext;
}>();

const emit = defineEmits<{
  send: [event: CourseSessionRegistrationWizardEvent];
}>();

const existing = ref<
  CourseSessionRegistrationWizardContext["customer"]["existing"]
>(properties.context.user.existing?.customer ?? undefined);
const request =
  ref<CourseSessionRegistrationWizardContext["customer"]["creation_request"]>();
const hubspot = ref<CourseSessionRegistrationWizardContext["hubspot"]>({
  deal_id: undefined,
  line_item_ids: undefined,
});

function onSubmit() {
  return emit("send", {
    type: "customer_selected",
    customer: {
      existing: existing.value,
      creation_request: request.value,
    },
    hubspot: hubspot.value,
  });
}
</script>
