<template>
  <app-form-autocomplete
    v-model="model"
    v-model:query="query"
    item-title="company_name"
    item-value="id"
    :items="items"
    label="Customer"
    placeholder="Search by company name"
    :readonly="disabled"
    :required="required"
  >
    <template #item="{ item, props }">
      <v-list-item
        v-bind="props"
        density="compact"
        :prepend-avatar="item.organization?.logo_url"
        :title="`${item.company_name}${
          item.identification_number ? ` - ${item.identification_number}` : ''
        }`"
      >
        <template v-if="!item.organization" #prepend>
          <v-avatar :size="32">
            {{ item.company_name.charAt(0).toUpperCase() }}
          </v-avatar>
        </template>
        <template #subtitle>
          Linked to :
          {{
            item.organization?.name ??
            item.users
              ?.map((u: DatabaseTable<"users">) => u.full_name)
              .join(", ")
          }}
        </template>
      </v-list-item>
    </template>

    <template v-if="$slots['no-data']" #no-data>
      <slot name="no-data" />
    </template>
  </app-form-autocomplete>
</template>

<script lang="ts" setup>
import type { CustomerEnrichedView } from "~~/model/customer.model";

const model = defineModel<CustomerEnrichedView | undefined>({
  default: undefined,
});
const query = defineModel<string>("query", { default: "" });

defineProps<{
  required?: boolean;
  disabled?: boolean;
}>();

const { data: items, execute } = useFetch("/api/customers", {
  immediate: false,
  watch: false,
  query: { query },
  default: () => [],
});

watchDebounced(query, (value) => {
  if ((value ?? "").length < 2) {
    return;
  }

  execute();
});
</script>
