<template>
  <div
    v-if="showUserForm && userCreationRequest"
    class="grid grid-cols-2 gap-x-4"
  >
    <div class="mb-2">
      <v-btn
        prepend-icon="ph:arrow-left"
        size="small"
        variant="text"
        @click="toggleUserForm(false)"
        >Back to user search</v-btn
      >
    </div>

    <app-form-input-text
      v-model="userCreationRequest.email"
      class="col-span-2"
      data-cy-email
      label="Email"
      required
      type="email"
    />

    <app-form-input-text
      v-model="userCreationRequest.first_name"
      data-cy-first-name
      label="First name"
      required
    />
    <app-form-input-text
      v-model="userCreationRequest.last_name"
      data-cy-last-name
      label="Last name"
      required
    />

    <app-form-input-text
      v-model="userCreationRequest.company_name"
      data-cy-company-name
      label="Company name"
      required
    />
    <app-form-input-text
      v-model="userCreationRequest.job_title"
      data-cy-job-title
      label="Job title"
      required
    />

    <div class="col-span-2">
      <v-checkbox-btn
        v-model="userCreationRequest.newsletter_subscribed"
        data-cy-newsletter-opt-out
        label="Subscribe created user to Pollen newsletter"
      />
    </div>
  </div>

  <user-autocomplete
    v-else
    v-model="user"
    v-model:query="query"
    data-cy-user-autocomplete
    required
  >
    <template v-if="query" #no-data>
      <p class="text-subtle flex items-center justify-between px-3 py-2">
        No existing user found
        <v-btn
          color="primary"
          data-cy-create-user
          size="small"
          variant="text"
          @click="toggleUserForm(true)"
          >Create new user</v-btn
        >
      </p>
    </template>
  </user-autocomplete>
</template>

<script lang="ts" setup>
import type { UserView } from "@pollen/core/server/services/user/user.service";

const user = defineModel<UserView | undefined>("user", { default: undefined });
const userCreationRequest = defineModel<
  | (Omit<DatabaseInsertRequest<"users">, "id"> & {
      newsletter_subscribed: boolean;
    })
  | undefined
>("userCreationRequest", {
  default: undefined,
});

const query = ref("");

const [showUserForm, toggleUserForm] = useToggle();

watch(showUserForm, (isFormShown) => {
  query.value = "";
  user.value = undefined;
  userCreationRequest.value = isFormShown
    ? {
        first_name: "",
        last_name: "",
        email: "",
        company_name: "",
        job_title: "",
        newsletter_subscribed: true,
      }
    : undefined;
});
</script>
