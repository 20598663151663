import { useMachine } from "@xstate/vue";
import get from "lodash/get";
import { assign, createMachine, fromPromise } from "xstate";

import CustomerStep from "~/business-areas/course-session-registration/components/wizard/customer-step.vue";
import OrganizationSelectionStep from "~/business-areas/course-session-registration/components/wizard/organization-step.vue";
import SubmissionStep from "~/business-areas/course-session-registration/components/wizard/submission-step.vue";
import UserSelectionStep from "~/business-areas/course-session-registration/components/wizard/user-step.vue";
import ValidationStep from "~/business-areas/course-session-registration/components/wizard/validation-step.vue";
import type {
  CourseSessionRegistrationWizardContext,
  CourseSessionRegistrationWizardEvent,
} from "~/business-areas/course-session-registration/course-session-registration.model";
import type { SessionPageView } from "~/pages/sessions/_includes/composables/session-view.hook";

function getStateValueStrings(stateValue: unknown): string[] {
  if (typeof stateValue === "string") return [stateValue];

  if (typeof stateValue === "object" && stateValue !== null) {
    const valueKeys = Object.keys(stateValue);

    return [
      ...valueKeys,
      ...valueKeys.flatMap((key) =>
        getStateValueStrings(stateValue[key as keyof typeof stateValue]!).map(
          (s) => key + "." + s,
        ),
      ),
    ];
  }

  return [];
}

export function useCourseSessionRegistrationWizard(
  session: SessionPageView,
  options?: {
    onComplete?: () => void;
  },
) {
  const context: CourseSessionRegistrationWizardContext = {
    session,
    free: false,
    prospect: false,
    attach_to_organization: undefined,
    user: {
      existing: undefined,
      creation_request: undefined,
    },
    customer: {
      existing: undefined,
      creation_request: undefined,
    },
    hubspot: {
      deal_id: undefined,
      line_item_ids: undefined,
    },
  };

  const machine = createMachine({
    id: "registration-wizard",

    types: {} as {
      events: CourseSessionRegistrationWizardEvent;
    },

    context,

    initial: "user_selection",

    states: {
      user_selection: {
        meta: {
          component: UserSelectionStep,
        },
        on: {
          user_creation_request_submitted: [
            {
              guard: ({ context }) =>
                !context.session.organization_session?.organization,
              target: "organization_selection",
              actions: assign({
                user: ({ event }) => {
                  return {
                    existing: undefined,
                    creation_request: event.request,
                  };
                },
                free: ({ event }) => event.free,
                prospect: ({ event }) => event.prospect,
              }),
            },
            {
              guard: ({ context }) =>
                !!context.session.organization_session?.organization,
              target: "validation",
              actions: assign({
                user: ({ event }) => {
                  return {
                    existing: undefined,
                    creation_request: event.request,
                  };
                },
                free: ({ event }) => event.free,
                prospect: ({ event }) => event.prospect,
              }),
            },
          ],
          user_selected: [
            {
              guard: ({ context }) =>
                !context.session.organization_session?.organization,
              target: "organization_selection",
              actions: assign({
                user: ({ event }) => {
                  return {
                    existing: event.user,
                    creation_request: undefined,
                  };
                },
                free: ({ event }) => event.free,
                prospect: ({ event }) => event.prospect,
              }),
            },
            {
              guard: ({ context }) =>
                !!context.session.organization_session?.organization,
              target: "validation",
              actions: assign({
                user: ({ event }) => {
                  return {
                    existing: event.user,
                    creation_request: undefined,
                  };
                },
                free: ({ event }) => event.free,
                prospect: ({ event }) => event.prospect,
              }),
            },
          ],
          organization_member_selected: {
            target: "validation",
            actions: assign({
              user: ({ event }) => {
                return {
                  existing: event.user,
                  creation_request: undefined,
                };
              },
              free: ({ event }) => event.free,
              prospect: ({ event }) => event.prospect,
            }),
          },
        },
      },
      organization_selection: {
        meta: {
          component: OrganizationSelectionStep,
        },
        on: {
          back: "user_selection",
          organization_selected: {
            actions: assign({
              attach_to_organization: ({ event }) => event.organization,
            }),
            target: "validation",
          },
          skip_organization_selection: [
            {
              actions: assign({
                attach_to_organization: undefined,
              }),
              guard: ({ context }) => !!context.free,
              target: "validation",
            },
            {
              actions: assign({
                attach_to_organization: undefined,
              }),
              guard: ({ context }) => !context.free && !!context.session.public,
              target: "customer_selection",
            },
          ],
        },
      },
      customer_selection: {
        meta: {
          component: CustomerStep,
        },
        on: {
          back: "organization_selection",
          customer_selected: {
            target: "validation",
            actions: assign({
              customer: ({ event }) => event.customer,
              hubspot: ({ event }) => event.hubspot,
            }),
          },
        },
      },
      validation: {
        meta: {
          component: ValidationStep,
        },
        on: {
          request_submitted: {
            target: "submission",
          },
        },
      },
      submission: {
        meta: {
          component: SubmissionStep,
        },
        invoke: {
          input: ({ context }) => context,
          src: fromPromise(({ input }) => {
            // @ts-expect-error excessive stack depth
            return $fetch(
              `/api/courses/${input.session.course_id}/sessions/${input.session.id}/register`,
              {
                method: "POST",
                body: {
                  free: input.free,
                  prospect: input.prospect,
                  user: {
                    existing: input.user.existing?.id,
                    creation_request: input.user.creation_request,
                  },
                  customer: {
                    existing: input.customer.existing?.id,
                    creation_request: input.customer.creation_request,
                  },
                  attach_to_organization: input.attach_to_organization,
                  hubspot: input.hubspot,
                },
              },
            );
          }),
          onDone: {
            actions: () => options?.onComplete?.(),
          },
          onError: {
            target: "user_selection",
            actions: ({ event }) =>
              useAlert().showError((event.error as Error).message),
          },
        },
      },
    },
  });

  const { snapshot, send } = useMachine(machine);
  const currentStateNodeId = computed(() => {
    return getStateValueStrings(snapshot.value.value).reverse()[0];
  });

  const view = computed(() => {
    const stringifiedDeepestState =
      "registration-wizard." + currentStateNodeId.value;

    return get(snapshot.value.getMeta(), stringifiedDeepestState).component;
  });

  return {
    state: snapshot,
    send,
    view,
  };
}

const WizardUtilitiesInjectionKey = Symbol("Wizard state injection key");

/*
That Hook is only used to handle back cta automatically in Wizard steps
without having to enable it manually for each step.
 */
type WizardUtilities = {
  send({ type }: { type: string }): void;
  state: Ref<{ can({ type }: { type: string }): boolean }>;
};

export function useCourseSessionRegistrationWizardUtilities() {
  return inject<WizardUtilities>(WizardUtilitiesInjectionKey);
}

export function provideWizardUtilities(utilities: WizardUtilities) {
  provide(WizardUtilitiesInjectionKey, utilities);
}
