import type { Ref } from "vue";

import type { CourseSessionRegistrationWizardContext } from "~/business-areas/course-session-registration/course-session-registration.model";

export function useCourseSessionRegistrationCreditCapacity(
  organization: Ref<
    CourseSessionRegistrationWizardContext["attach_to_organization"]
  >,
  context: CourseSessionRegistrationWizardContext,
) {
  const walletBalanceNeedsToBeValidated = computed(() => {
    return !!context.session.public && !context.free;
  });
  const creditsNeeded = computed(
    () =>
      context.session.products.find((p) => p.key !== "MENTORING")
        ?.credit_value ?? 0,
  );
  const disabled = ref(false);
  const warningMessage = ref<string | undefined>();

  watch(organization, async (value) => {
    if (!walletBalanceNeedsToBeValidated.value) return;

    warningMessage.value = undefined;

    if (!value) {
      disabled.value = false;
      return;
    }

    disabled.value = true;

    const { data: wallet } = await useDatabaseClient()
      .from("organization_view_wallets")
      .select()
      .eq("organization_id", value)
      .single();

    disabled.value = (wallet?.balance ?? 0) < creditsNeeded.value;

    if (disabled.value) {
      warningMessage.value = `Selected organization does not have enough credits: ${wallet?.balance} in wallet - ${creditsNeeded.value} needed`;
    }
  });

  return {
    disabled,
    warningMessage,
  };
}
