<template>
  <course-session-registration-wizard-step
    icon="ph:check-circle"
    title="Validation"
    @submit="onSubmit"
  >
    <strong>{{ userName }}</strong>
    <small class="text-subtle">
      ({{
        properties.context.user.existing ? "existing user" : "new user"
      }})</small
    >
    will be :

    <ul class="mt-4 grid gap-2">
      <li v-for="item in checkList" :key="item" class="flex items-center gap-2">
        <v-icon class="text-success" icon="ph:check" :size="20" />
        {{ item }}
      </li>
    </ul>
  </course-session-registration-wizard-step>
</template>

<script lang="ts" setup>
import type {
  CourseSessionRegistrationWizardContext,
  CourseSessionRegistrationWizardEvent,
} from "~/business-areas/course-session-registration/course-session-registration.model";

const properties = defineProps<{
  context: CourseSessionRegistrationWizardContext;
}>();
const emit = defineEmits<{
  send: [event: CourseSessionRegistrationWizardEvent];
}>();

const userName = computed(
  () =>
    properties.context.user.existing?.full_name ??
    `${properties.context.user.creation_request?.first_name} ${properties.context.user.creation_request?.last_name}`,
);

const { data: attachToOrganization } = await useAsyncData(async () => {
  if (properties.context.attach_to_organization === undefined) return;

  const { data } = await useDatabaseClient()
    .from("organizations")
    .select()
    .eq("id", properties.context.attach_to_organization)
    .single();

  return data!;
});

function onSubmit() {
  return emit("send", { type: "request_submitted" });
}

const checkList = computed<string[]>(() => {
  const list = ["registered to that session"];

  if (properties.context.user.creation_request?.newsletter_subscribed) {
    list.push("subscribed to Pollen newsletter");
  }

  if (attachToOrganization.value) {
    list.push(`attached to ${attachToOrganization.value.name} organization`);
  }

  return list;
});
</script>
