<template>
  <div v-if="showCustomerForm && customerCreationRequest">
    <div class="mb-2">
      <v-btn
        prepend-icon="ph:arrow-left"
        size="small"
        variant="text"
        @click="toggleCustomerForm(false)"
        >Back to customer search</v-btn
      >
    </div>

    <app-form-input-text
      v-model="customerCreationRequest.company_name"
      data-cy-customer-company
      label="Company"
      required
    />
  </div>

  <customer-autocomplete
    v-else
    v-model="customer"
    v-model:query="query"
    data-cy-customer-autocomplete
    :disabled="disabled"
    required
  >
    <template #no-data>
      <p class="text-subtle flex items-center justify-between px-3 py-2">
        {{ query ? "No existing customer found" : "Need a new customer?" }}
        <v-btn
          color="primary"
          data-cy-create-customer
          size="small"
          variant="text"
          @click="toggleCustomerForm(true)"
          >Create new customer</v-btn
        >
      </p>
    </template>
  </customer-autocomplete>
</template>

<script lang="ts" setup>
defineProps<{
  disabled?: boolean;
}>();

const customer = defineModel<DatabaseTable<"customers"> | undefined>(
  "customer",
  {
    default: undefined,
  },
);
const customerCreationRequest = defineModel<
  Pick<DatabaseInsertRequest<"customers">, "company_name"> | undefined
>("customerCreationRequest", {
  default: undefined,
});

const query = ref("");

const [showCustomerForm, toggleCustomerForm] = useToggle();

watch(showCustomerForm, (isFormShown) => {
  query.value = "";
  customer.value = undefined;
  customerCreationRequest.value = isFormShown
    ? {
        company_name: "",
      }
    : undefined;
});
</script>
